.RegistrationForm {

}

.RegistrationForm_fields {
    display: flex;

    & > * {
        flex: 1 1 auto;
        &:first-child {
            margin-right: 12px;
        }
    }
}

.RegistrationForm_submit {
    margin-top: 32px;
}

.checkbox_size_l .checkbox__box, .checkbox_size_l .checkbox__box:after {
    width: 16px !important;
    height: 16px !important;
}

.RegistrationForm_checkbox {
    padding-top: 10px;
    cursor: pointer;
    .checkbox_focused .checkbox__box:after {
        border: none !important;
    }
    .checkbox__box {
        border-radius: 0 !important;
        border: 2px solid rgba(11, 31, 53, 0.25) !important;
        &:hover {
            border: 2px solid #3f2aff !important;
        }
    }
    .checkbox_checked {
        .checkbox__box {
            background: #3d3bff99 !important;
            border: 2px solid rgba(0, 0, 0, 00) !important;
        }
    }
    .RegistrationForm_checkbox_inner {
        //padding-top: 4px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

        a {
            text-decoration: none;
        }
    }
}