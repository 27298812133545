@import "../../styles/common";

.DocumentSignForm {
    //min-width: 327px;
    //@include mq(desktop) {
    //    width: 512px;
    //}
}

.DocumentSignForm_inner {
    display: flex;
    align-items: center;
    & > * {
        margin-right: 8px;
    }
}