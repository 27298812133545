@import "../../styles/common";

.PageDetails {

}

.PageDetails_nav {
    margin-bottom: 20px;
    @include print {
        display: none;
    }
}