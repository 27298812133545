@import "../../styles/common";

.Input {
    &.input_size_s {
        .input__control {
            outline: none;
            font-size: 15px;
            height: 38px;
            padding: 0 16px;
        }

        .input__box {
            padding: 0;
            height: 38px;
        }

        &:hover {
            background-color: #F7F7F5;
        }

        &.input_pin {
            .input__control {
                letter-spacing: 10px;
            }
        }
        &.input_er {
            .input__box {
                background-color: #FFE7E7;
            }
        }
    }

    &.input_has-value {
        background-color: #F7F7F5 !important;
    }

    .input__control {
        &::placeholder {
            color: $color-placeholder;
        }
    }

    .input__box {
        border: 1px solid $color-border;
        border-radius: $corner-radius;
        //box-shadow: none !important;
        //background: none !important;
    }

    &.input_focused .input__box {
        border: 1px solid $color-gray-muted;
        background-color: #F7F7F5;
    }

    &.input_invalid .input__box {
        border-color: $color-red;
    }

    &.input_focused {
        background-color: #FFFFFF;
    }

}
