@import "../../styles/common";

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq(desktop) {
        padding: 46px 135px 49px 135px;
    }
    @include mq(mobile) {
        padding: 20px $padding-side;
    }

    @include print {
        display: none;
    }
}

.Header_logo {
    display: inline-flex;
    align-items: center;

    img {
        display: block;
        @include mq(desktop) {
            max-width: 218px;
        }
        @include mq(mobile) {
            max-width: 162px;
        }
    }
}

.Header_controls {
    display: flex;
    align-items: center;
}

.Header_user {
    @include mq(mobile) {
        display: none !important;
    }
}

.Header_userName {
    text-decoration: none;
    color: inherit;
    &:hover {
        color: $color-new-blue
    }
}

.Header_userCompany {
    color: #919399
}

.Header_signout {
    flex: 0 0 auto;
    width: 40px;
    min-width: 0;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    background: $color-gray-light;
    border: 0;
    &:hover {
        background: $color-gray-muted;
        color: #fff;
    }
}