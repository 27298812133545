@import "../../styles/common";

.Select {
    .select-button {
        padding: 0 12px;
        border: 1px solid $color-border;
        border-radius: $corner-radius;
        font-size: $textSizeMD;
        height: 40px;
        line-height: 40px;
        min-height: 40px;
        box-shadow: none !important;

        &:focus {
            border-color: $color-gray-muted;
        }
    }

    .select__placeholder {
        color: $color-placeholder !important;
    }

    &.select_opened {
        .select-button {
            border-color: $color-gray-muted;
        }
    }

    &.select_invalid {
        .select-button {
            border: 1px solid $color-red;
        }
    }
}