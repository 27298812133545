// @import "../../styles/common";
.InputPhone {
    border-bottom: 2px solid rgba(11, 31, 53, 0.15);
    border-radius: 4px 4px 0 0;
    padding: 0 10px;
    display: inline-flex;
    width: 100%;
    background-color: rgba(11, 31, 53, 0.05);

    .PhoneInputInput {
        font-family: inherit;
        border-radius: 4px 4px 0 0;
        border: none;
        font-size: 15px;
        padding: 8px 0;
        height: 42px;
        outline: none;
        color: #0b1f35;
        background: transparent;
        &::placeholder {
            color: rgba(11, 31, 53, 0.15);
            font-size: 15px;
        }
    }
}
