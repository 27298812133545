@import "../../styles/common";

.Signin {

}

.Signin_title {
    margin-bottom: 20px;
}

.Signin_footer {
    display: flex;
    align-items: center;
    & > * {
        margin-left: 24px;
        &:first-child {
            margin-left: 0;
        }
    }
}

.Signin_recovery {
    .Signin & {
        color: $color-gray-muted;
        &:hover {
            color: $color-red
        }
        &:focus {
            outline: 2px solid $color-gray-muted
        }
    }
}