@import "../../styles/common";

.Button {
    .button__text {
        font-size: 15px;
    }

    .button__icon {
        margin: 0;
        padding: 0;
    }

}

.Button.Button__noSizeChange {

    .button__text {
        font-size: 18px;
    }
}

.Button.button_view_extra {
    background: $color-new-blue;
    border-color: $color-border;
}

.button_view_extra.button_hovered {
        background-color: #000000 !important;
        border-color: #000000 !important;
        color: #FFFFFF
}

.Button.button_view_action {
    background: $color-gray-light;
    border-color: $color-border;
    &:hover {
        background-color: #000000 !important;
        border-color: #000000 !important;
        color: #FFFFFF
    }
}

.Button.Button__active {
    border-color: $color-new-blue;
    border-width: 2px;
    &:hover {
        background-color: #000000 !important;
        border-color: #000000 !important;
        color: #FFFFFF
    }

    .button__text {
        font-weight: bold;
    }
}

.Button.Button__transparent {
    border-color: transparent;

    .button__text {
        border-bottom: 1px solid;
    }

    &:hover {
        border-color: #000000;
    }
}

.button_disabled {
    background-color: #00000020 !important;
    border-color: #000000;
}